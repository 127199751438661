import { Api } from "@/models/class/api.class";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/HttpClient.service";
import { AxiosError } from "axios";
import { RequestAssetAdditions, ResponseAssetSingle } from "../types";

export class AssetAdditionServices extends HttpClient {
  createAssetAdditionsDraft = (
    payload: RequestAssetAdditions
  ): Promise<ResponseAssetSingle> => {
    return this.post<ResponseAssetSingle, RequestAssetAdditions>(
      Api.AssetAditionsDraft,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  // untuk yang edit di import > posting asset > action > update
  createAssetAdditionsDraftWithId = (
    payload: RequestAssetAdditions,
    id: string
  ): Promise<ResponseAssetSingle> => {
    return this.post<ResponseAssetSingle, RequestAssetAdditions>(
      Api.AssetAditionsDraft + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createAssetAdditions = (
    payload: RequestAssetAdditions
  ): Promise<ResponseAssetSingle> => {
    return this.post<ResponseAssetSingle, RequestAssetAdditions>(
      Api.AssetAditions,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  updateAssetAdditions = (
    payload: RequestAssetAdditions,
    id: string
  ): Promise<ResponseAssetSingle> => {
    return this.put<ResponseAssetSingle, RequestAssetAdditions>(
      Api.AssetAditions + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  cancelAssetAdditions = (id: string): Promise<ResponseAssetSingle> => {
    return this.put<ResponseAssetSingle, void>(
      Api.CancelAssetAdditions + `/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
}

export const assetAdditionServices = new AssetAdditionServices();
